import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții eficiente</span>
                            <h3>Despre securitatea cibernetică</h3>
                            <p>Securitatea cibernetică se referă la protejarea sistemelor conectate la internet, inclusiv hardware, software și date, de atac, degradare sau acces neautorizat. La Vertical Digital, oferim o gamă largă de servicii de securitate cibernetică pentru a ajuta organizațiile să se protejeze împotriva amenințărilor cibernetice. Echipa noastră de experți în securitate cibernetică are cunoștințele și expertiza necesare pentru a ajuta organizația dvs. să rămână în siguranță și secură online.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                           <p>Procesul nostru de securitate cibernetică începe cu o evaluare amănunțită a poziției actuale de securitate cibernetică a organizației dvs. Lucrăm cu dvs. pentru a identifica orice vulnerabilități potențiale și a dezvolta un plan pentru a le aborda.</p>

                                           <p>Apoi, vă ajutăm să implementați măsurile de securitate cibernetică necesare pentru a proteja sistemele și datele dvs. Acest lucru poate include instalarea firewall-urilor și a software-ului antivirus, implementarea controlului accesului și dezvoltarea politicilor și procedurilor pentru a asigura securitatea sistemelor dvs.</p>

                                           <p>În timpul procesului, oferim suport și orientare continuă pentru a asigura succesul eforturilor dvs. de securitate cibernetică. Lucrăm cu dvs. pentru a monitoriza sistemele, a identifica orice amenințări potențiale și a lua orice măsuri necesare pentru a proteja organizația dvs.
                                         </p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați ajutorării organizațiilor să rămână în siguranță și sigure online. Echipa noastră de experți în securitate cibernetică are cunoștințele și expertiza necesare pentru a ajuta organizația dvs. să se protejeze împotriva amenințărilor cibernetice. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta să securizați sistemele și datele dvs</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Kotlin</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Docker</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent